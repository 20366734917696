import React from 'react';

const Terms: React.FC = () => {
    return (
        <>
            <head>
                <title>Solt - The place for foodies and food reviewers</title>
                <meta
                    name="description"
                    content="Explore the best dishes at top restaurants, handpicked by food content creators. Discover unique flavors, see where to eat them, and share your own foodie favorites."
                />
            </head>
            <div style={{padding: '2rem'}}>
                <h1>Terms of Service</h1>
                <p>Effective Date: 6 December 2024</p>
                <p>Version: 1.0</p>

                {/*<h2>Welcome to Solt!</h2>*/}
                <p>
                    Welcome to Solt!
                    These Terms of Service (“Terms”) govern your use of the website located at{' '}
                    <a href="https://www.solt.app">www.solt.app</a> (the “Site”), our mobile applications
                    (“App”), and any related services provided by Jerry Technologies SRL (“Solt,” “we,” “our,” or “us”).
                    Collectively, the Site, App, and services are referred to as the “Services.”
                </p>
                <p>
                    Please read these Terms and our{' '}
                    <a href="https://www.solt.app/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>{' '}
                    carefully, as they govern your use of the Services.
                </p>

                <h2>1. Agreement to Terms</h2>
                <p>
                    These Terms govern your use of the Services from the moment you create an account, access the
                    Services, or otherwise interact
                    with the Services. By creating an account or accessing our Services in any capacity, you agree to be
                    bound by these Terms.
                </p>
                <p>
                    If you do not agree, do not access or use the Services. If you are accessing or using the Services
                    on behalf of a company (such as
                    your employer) or other legal entity, you represent and warrant that you have the authority to bind
                    that entity to these Terms. In such
                    cases, “you” and “your” refer to that entity.
                </p>
                <p>
                    <strong>Updates to Terms:</strong> We reserve the right to update these Terms at our discretion. We
                    will notify you of material changes
                    through the Services, email, or other reasonable means. Your continued use of the Services
                    constitutes acceptance of the updated Terms.
                </p>

                <h2>2. Definitions</h2>
                <ul>
                    <li>
                        <strong>Solt, We, Us:</strong> Refers to Jerry Technologies SRL, a company registered in
                        Romania, with registration number:
                        RO46294133.
                    </li>
                    <li>
                        <strong>User, You, Your:</strong> Refers to any individual or entity that accesses, registers
                        for, or uses the Services. Users may
                        include:
                        <ul>
                            <li><strong>Creators:</strong> Users who upload, share, or otherwise create content on the
                                Services.
                            </li>
                            <li><strong>Consumers:</strong> Users who consume content or interact with the Services
                                without uploading or creating content.
                            </li>
                        </ul>
                    </li>
                    <li><strong>Site:</strong> Refers to the website located at www.solt.app.</li>
                    <li><strong>App:</strong> Refers to our mobile applications available on supported platforms.</li>
                    <li>
                        <strong>Content:</strong> Refers to all user-generated material uploaded, shared, or otherwise
                        made available on the Services,
                        including images, videos, text, reviews, recommendations, and other materials.
                    </li>
                    <li>
                        <strong>Services:</strong> Refers collectively to the Site, App, and all related services we
                        provide.
                    </li>
                </ul>

                <h2>3. Applicability of Terms</h2>
                <p>
                    These Terms govern your use of the Services from the moment you create an account or access the
                    Services, and they will continue
                    to apply unless replaced by updated Terms. These Terms also govern any content you upload to the
                    Services and any data collected
                    about you through your use of the Services.
                </p>
                <p>
                    If we update these Terms in the future, you will be notified and required to agree to the updated
                    Terms to continue using the
                    Services.
                </p>

                <h2>4. Eligibility</h2>
                <p>You must meet the following age requirements to use the Services:</p>
                <ul>
                    <li>
                        <strong>Minimum Age:</strong> You must be at least 16 years old, or the minimum age required in
                        your country to consent to use
                        the Services.
                    </li>
                    <li>
                        <strong>Parental Consent for Minors:</strong> If you are under 18, you must have your parent or
                        legal guardian’s permission to
                        use the Services and ask them to read these Terms with you.
                    </li>
                </ul>
                <p>
                    <strong>Prohibited Use by Underage Users:</strong> If you are below the minimum age in your country
                    or do not have parental consent
                    as required, you are strictly prohibited from using the Services.
                </p>
                <p>
                    <strong>Account Termination for Underage Users:</strong> We reserve the right to terminate or
                    suspend any account if we discover or
                    have reason to believe that the account is being used by someone below the required age.
                </p>

                <h2>5. User-Generated Content</h2>
                <p>
                    <strong>Ownership of Content:</strong> You retain ownership of any content you upload or share on
                    the Services.
                </p>
                <p>
                    <strong>License to Us:</strong> By posting or sharing content, you grant us a worldwide, perpetual,
                    irrevocable, royalty-free,
                    transferable, and sub-licensable license to:
                </p>
                <ul>
                    <li>Use, reproduce, modify, adapt, publish, distribute, publicly perform, publicly display, and
                        create derivative works of your content.
                    </li>
                    <li>
                        Use your content for any purpose, including but not limited to marketing, advertising,
                        promotional activities, platform
                        development, partnerships, and redistribution on any platform or medium.
                    </li>
                    <li>
                        Display your content in connection with other content or promotional materials across our
                        accounts, platforms, or affiliated
                        services.
                    </li>
                </ul>
                <p>
                    <strong>Scope of License:</strong> This license applies to:
                </p>
                <ul>
                    <li>All existing content and data associated with your account, regardless of when it was uploaded
                        or posted.
                    </li>
                    <li>Any future content or data you upload, share, or otherwise make available through the
                        Services.
                    </li>
                </ul>
                <p>
                    <strong>Retention After Account Termination:</strong> We retain the right to use your content
                    indefinitely, even after you delete your
                    account or terminate your relationship with us, for legal, operational, marketing, or promotional
                    purposes.
                </p>
                <p>
                    <strong>Content Removal:</strong> We may continue to use your content even if you delete it or
                    terminate your account. We also reserve
                    the right to remove or modify any content that violates these Terms or applicable laws.
                </p>
                <p>
                    <strong>User Responsibility for Content:</strong> You represent and warrant that you own all rights
                    to the content you upload. Your
                    content must not infringe on any third-party rights, violate applicable laws, or include illegal,
                    offensive, or abusive material.
                </p>


                <h2>6. Analytics</h2>
                <p>
                    We collect analytics data to monitor and improve the Services. This includes, but is not limited to:
                </p>
                <ul>
                    <li>Behavioral data, such as mouse movements, clicks, scrolling activity, and heatmaps.</li>
                    <li>Session recordings, which capture user interactions with the Services.</li>
                    <li>Device information, IP addresses, and general usage patterns.</li>
                </ul>
                <p>
                    We may use third-party tools to gather this data. These tools may store anonymized or aggregated
                    data on their servers, subject to
                    their respective privacy policies.
                </p>
                <p>
                    Analytics data is used solely for improving the functionality, design, and overall user experience
                    of the Services. Where required by
                    applicable law, we will request explicit consent before collecting certain types of analytics data.
                </p>
                <p>
                    For more information, please review our Privacy Policy at{' '}
                    <a href="https://www.solt.app/privacy" target="_blank" rel="noopener noreferrer">
                        https://www.solt.app/privacy
                    </a>
                    .
                </p>


                <h2>7. Prohibited Activities</h2>
                <p>You agree not to:</p>
                <ul>
                    <li>Upload illegal, abusive, or offensive content.</li>
                    <li>Use the Services for spam, fraud, or unlawful activities.</li>
                    <li>Attempt to hack, disrupt, or reverse-engineer the Services.</li>
                    <li>Violate any applicable laws while using the Services.</li>
                </ul>

                <h2>8. Privacy</h2>
                <p>
                    Our use of your personal data is governed by our{' '}
                    <a href="https://www.solt.app/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                    . Please review it to understand how we collect, use, and protect your data.
                </p>

                <h2>9. Intellectual Property</h2>
                <p>
                    <strong>Our Content:</strong> We own all rights, title, and interest in the Services’ design, code,
                    and proprietary content (excluding
                    user-generated content).
                </p>
                <p>
                    <strong>Restrictions:</strong> You may not copy, distribute, modify, or reverse-engineer any part of
                    the Services.
                </p>

                <h2>10. Changes to These Terms or the Services</h2>
                <p>
                    We reserve the right to update these Terms at any time at our sole discretion. If changes are made,
                    we will notify you by posting the
                    updated Terms on the Site or within the App, and we may also send additional communications.
                </p>
                <p>
                    We encourage you to review the Terms regularly to stay informed about any updates. By continuing to
                    use the Services after the
                    updated Terms are posted, you agree to be bound by the revised Terms. If you do not agree to the
                    changes, you must stop using the
                    Services immediately.
                </p>
                <p>
                    As part of our commitment to improving the platform, we may modify, suspend, or discontinue any part
                    of the Services at any time,
                    without prior notice and at our sole discretion.
                </p>

                <h2>11. Termination</h2>
                <p>
                    <strong>Termination by Us:</strong> We may suspend or terminate your access to and use of the
                    Services, including suspending
                    access to or terminating your account, at any time, for any reason, and at our sole discretion,
                    without prior notice or liability.
                </p>
                <p>
                    <strong>Termination by You:</strong> You may terminate your account at any time by contacting us
                    at{' '}
                    <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>
                <p>
                    <strong>Effect of Termination:</strong> Upon termination, all licenses and rights granted to you
                    under these Terms will immediately
                    cease. We retain the right to continue using any content you have uploaded, and the following
                    sections will survive termination: 5,
                    6, 10, 11, 12, 13, 14, 15, and 16.
                </p>

                <h2>12. Warranty Disclaimers</h2>
                <p>
                    <strong>"As Is" Services:</strong> The Services are provided "as is," without warranties of any
                    kind. We explicitly disclaim all implied
                    warranties, including but not limited to merchantability, fitness for a particular purpose, quiet
                    enjoyment, and non-infringement.
                </p>
                <p>
                    <strong>No Guarantee of Availability:</strong> We do not guarantee that the Services will meet your
                    requirements or that they will
                    be available on an uninterrupted, secure, or error-free basis.
                </p>
                <p>
                    <strong>Third-Party Content:</strong> The Services may include materials provided by third parties.
                    We are not responsible for the
                    accuracy, reliability, or completeness of such materials.
                </p>

                <h2>13. Limitation of Liability</h2>
                <p>
                    To the fullest extent permitted by law, we will not be liable for:
                </p>
                <ul>
                    <li>Lost profits, loss of data, or content.</li>
                    <li>Service interruptions, system failures, or the cost of substitute services.</li>
                </ul>
                <p>
                    In no event will our total liability for any claims exceed 1 euro.
                </p>

                <h2>14. Community Guidelines</h2>
                <p>To maintain a respectful and engaging environment, you agree to:</p>
                <ul>
                    <li>Avoid posting content that is abusive, offensive, defamatory, or discriminatory.</li>
                    <li>Interact with other users in a respectful manner.</li>
                    <li>Report any violations of these guidelines to us.</li>
                </ul>
                <p>
                    Failure to comply with these guidelines may result in content removal, account suspension, or
                    termination at our sole discretion.
                </p>

                <h2>15. Payment Terms (if applicable)</h2>
                <p>
                    If the Services include premium features, subscriptions, or in-app purchases:
                </p>
                <ul>
                    <li>You agree to pay all applicable fees, which are non-refundable except as required by law.</li>
                    <li>We reserve the right to change pricing or payment terms at any time.</li>
                    <li>Any taxes or other fees associated with the purchase are your responsibility.</li>
                </ul>

                <h2>16. Feedback and Suggestions</h2>
                <p>
                    By providing feedback, suggestions, or ideas about the Services (“Feedback”), you grant us a
                    perpetual, irrevocable, royalty-free,
                    transferable, and sublicensable license to use, modify, and commercialize the Feedback for any
                    purpose, without compensation.
                </p>

                <h2>17. Third-Party Services</h2>
                <p>
                    The Services may include links to or integrations with third-party services. We do not endorse or
                    control these third-party services
                    and are not responsible for their content or practices. Use of third-party services is at your own
                    risk.
                </p>

                <h2>18. Service Availability</h2>
                <p>
                    We do not guarantee uninterrupted availability of the Services and may modify, suspend, or terminate
                    the Services at any time without
                    liability.
                </p>

                <h2>19. Force Majeure</h2>
                <p>
                    We are not liable for delays or failures caused by circumstances beyond our reasonable control,
                    including but not limited to acts of
                    God, natural disasters, government actions, cyberattacks, or technical failures.
                </p>

                <h2>20. Governing Law</h2>
                <p>
                    These Terms are governed by and construed in accordance with the laws of Romania, without regard to
                    its conflict of law principles.
                </p>

                <h2>21. Dispute Resolution</h2>
                <p>
                    <strong>Governing Law:</strong> These Terms are governed by and construed in accordance with the
                    laws of Romania, without regard to
                    its conflict of law principles.
                </p>
                <p>
                    <strong>Exclusive Jurisdiction:</strong> Any disputes will be resolved in the courts located in
                    Romania.
                </p>
                <p>
                    <strong>No Class Actions:</strong> You agree to resolve disputes individually and waive your right
                    to participate in class actions or
                    collective litigation.
                </p>
                <p>
                    We reserve the right to determine the method and forum for resolving any disputes, including
                    arbitration or litigation.
                </p>

                <h2>22. Indemnification</h2>
                <p>
                    You agree to indemnify and hold us, our affiliates, and our respective officers, employees, and
                    agents harmless from any claims,
                    damages, or expenses arising out of:
                </p>
                <ul>
                    <li>Your content.</li>
                    <li>Your violation of these Terms.</li>
                    <li>Your use of the Services in violation of any applicable laws or third-party rights.</li>
                </ul>

                <h2>23. Mobile App-Specific Terms</h2>
                <p>
                    By downloading or using our App, you agree to the terms and conditions set forth by the relevant app
                    store (e.g., Apple App Store or
                    Google Play).
                </p>

                <h2>24. Feedback on Translation or Language</h2>
                <p>
                    If these Terms are provided in multiple languages, the English version will prevail in the event of
                    any conflict or discrepancy.
                </p>

                <h2>25. Promotional Communications</h2>
                <p>
                    By using the Services, you agree to receive promotional emails or notifications from us. You may opt
                    out at any time by following the
                    unsubscribe link or contacting us at <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>

                <h2>26. Partnerships, Acquisitions, and Data Usage</h2>
                <p>
                    <strong>Transfer of Content and Data in Business Changes:</strong> If we enter into a partnership,
                    are acquired by another entity, merge,
                    or undergo any form of corporate transformation, all user content, data, and accounts may be
                    transferred to the new entity or partner.
                    By agreeing to these Terms, you consent to such a transfer and acknowledge that the new entity will
                    inherit all rights and obligations
                    under these Terms.
                </p>
                <p>
                    <strong>Business Partnerships and Data Use:</strong> We reserve the right to establish B2B
                    partnerships or collaborations with third
                    parties and may share or utilize user data, content, and analytics in any manner that supports the
                    partnership, platform growth, or
                    operational objectives. Such usage may include, but is not limited to, marketing, product
                    development, advertising, or other
                    commercial purposes, as long as it complies with applicable laws and our Privacy Policy.
                </p>
                <p>
                    <strong>Notice of Significant Changes:</strong> In the event of significant business changes, we
                    will notify you through the Services,
                    email, or other reasonable communication methods.
                </p>

                <h2>27. General Terms</h2>
                <p>
                    <strong>27.1 Reservation of Rights:</strong> We and our licensors exclusively own all rights, title,
                    and interest in and to the Services,
                    including all associated intellectual property rights. You acknowledge that the Services are
                    protected by copyright and other
                    applicable intellectual property laws.
                </p>
                <p>
                    We reserve the right to apply for and enforce trademark, service mark, or other proprietary rights
                    protections in the future. You agree
                    not to remove, alter, or obscure any copyright, potential trademark, or other proprietary rights
                    notices incorporated in or
                    accompanying the Services.
                </p>
                <p>
                    <strong>27.2 Entire Agreement:</strong> These Terms constitute the entire and exclusive
                    understanding and agreement between us and
                    you regarding the Services and supersede all prior oral or written communications, understandings,
                    or agreements between us and
                    you regarding the Services.
                </p>
                <p>
                    Except where provided by applicable law, you may not assign or transfer these Terms, by operation of
                    law or otherwise, without our
                    prior written consent. Any attempt to assign or transfer these Terms without such consent will be
                    null and void. We may freely assign
                    or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and
                    benefit the parties, their successors,
                    and permitted assigns.
                </p>
                <p>
                    <strong>27.3 Notices:</strong> Any notices or other communications provided by us under these Terms
                    will be made by:
                </p>
                <ul>
                    <li>(i) sending an email to the address associated with your account; or</li>
                    <li>(ii) posting the notice on the Services.</li>
                </ul>
                <p>
                    The date of receipt will be deemed the date on which such notice is transmitted or posted.
                </p>
                <p>
                    <strong>27.4 Waiver of Rights:</strong> Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver
                    of such right or provision. A waiver of any right or provision will only be effective if it is in
                    writing and signed by an authorized
                    representative of our team.
                </p>
                <p>
                    Except as expressly provided in these Terms, the exercise of any remedy under these Terms by either
                    party will be without prejudice
                    to any other remedies available under these Terms or otherwise.
                </p>

                <h2>28. Contact Information</h2>
                <p>If you have any questions about these Terms or the Services, you may contact us at:</p>
                <p>Email: <a href="mailto:hello@solt.app">hello@solt.app</a></p>
                <p>Website: <a href="https://www.solt.app">www.solt.app</a></p>
            </div>
        </>
    );
};

export default Terms;
