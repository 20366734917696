import React from 'react';
import './Privacy.css';

const Privacy: React.FC = () => {
    return (
        <>
            <head>
                <title>Solt - The place for foodies and food reviewers</title>
                <meta
                    name="description"
                    content="Explore the best dishes at top restaurants, handpicked by food content creators. Discover unique flavors, see where to eat them, and share your own foodie favorites."
                />
            </head>
            <div style={{ padding: '2rem' }}>
                <h1>Privacy Policy</h1>
                <p>Effective Date: 6 December 2024</p>
                <p>Version: 1.0</p>

                {/*<h2>Welcome to Solt!</h2>*/}
                <p>
                    Welcome to Solt!
                    This Privacy Policy describes how Jerry Technologies SRL (“Solt,” “we,” “our,” or “us”) collects, uses, shares, and protects your
                    personal data when you use our website, mobile applications, or related services (collectively, the “Services”).
                </p>
                <p>
                    Please read this Privacy Policy carefully to understand our practices regarding your personal data. By using the Services, you agree
                    to the terms of this Privacy Policy.
                </p>

                <h2>1. Information We Collect</h2>
                <h3>1.1. Information You Provide</h3>
                <ul>
                    <li><strong>Account Information:</strong> Name, email address, and other registration details.</li>
                    <li><strong>User-Generated Content:</strong> Images, reviews, text, recommendations, or other materials uploaded by you.</li>
                    <li>
                        <strong>Payment Information:</strong> If applicable, payment details provided for premium features or subscriptions.
                    </li>
                </ul>
                <h3>1.2. Information We Automatically Collect</h3>
                <ul>
                    <li>
                        <strong>Device Information:</strong> Device type, operating system, browser type, and IP address.
                    </li>
                    <li>
                        <strong>Usage Data:</strong> Behavioral data, such as mouse movements, clicks, scrolling activity, and session recordings
                        using tools like Hotjar or Microsoft Clarity.
                    </li>
                    <li>
                        <strong>Cookies and Tracking:</strong> Cookies and similar technologies to track your interactions with our Services and
                        remember your preferences.
                    </li>
                </ul>
                <h3>1.3. Information from Third Parties</h3>
                <ul>
                    <li>
                        <strong>Analytics Tools:</strong> We use tools like Metabase, Google Analytics, and others to gather aggregated and
                        anonymized usage data.
                    </li>
                    <li>
                        <strong>Third-Party Accounts:</strong> If you connect your account to a third-party service (e.g., logging in via social
                        media), we may collect profile information from that service.
                    </li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <h3>2.1. To Provide and Improve Our Services</h3>
                <ul>
                    <li>Operate, maintain, and improve the functionality and performance of the Services.</li>
                    <li>Personalize your experience by understanding your preferences.</li>
                </ul>
                <h3>2.2. For Analytics and Tracking</h3>
                <ul>
                    <li>
                        Analyze user behavior, interactions, and trends to improve usability and design.
                    </li>
                    <li>
                        Use tools like Hotjar and Metabase to gather insights on how users interact with our Services.
                    </li>
                </ul>
                <h3>2.3. For Business Operations</h3>
                <ul>
                    <li>Facilitate payments, subscriptions, and other transactions.</li>
                    <li>
                        Communicate with you about updates, changes to the Services, or customer support inquiries.
                    </li>
                </ul>
                <h3>2.4. For Marketing and Partnerships</h3>
                <ul>
                    <li>Send promotional communications, which you may opt out of at any time.</li>
                    <li>
                        Share aggregated data with business partners to support partnerships, marketing, or platform growth.
                    </li>
                </ul>
                <h3>2.5. For Legal and Security Purposes</h3>
                <ul>
                    <li>
                        Detect, investigate, and prevent fraudulent activities, unauthorized access, or other malicious behavior.
                    </li>
                    <li>Comply with applicable laws, regulations, or legal processes.</li>
                </ul>

                <h2>3. How We Share Your Information</h2>
                <h3>3.1. With Service Providers</h3>
                <p>
                    Third-party vendors who assist in hosting, analytics, payment processing, or email delivery.
                </p>
                <h3>3.2. For Business Partnerships</h3>
                <p>
                    Aggregated or anonymized data may be shared with partners for marketing, platform development, or operational goals.
                </p>
                <h3>3.3. During Business Changes</h3>
                <p>
                    In the event of a merger, acquisition, or sale of assets, user data may be transferred to the new entity, subject to the same
                    protections outlined in this Privacy Policy.
                </p>
                <h3>3.4. When Required by Law</h3>
                <p>
                    To comply with legal obligations or respond to lawful requests from public authorities.
                </p>
                <h3>3.5. With Your Consent</h3>
                <p>
                    If you explicitly agree to share your data with other parties for specific purposes.
                </p>

                <h2>4. Data Security</h2>
                <p>
                    We take reasonable measures to protect your personal data from unauthorized access, loss, or misuse. These measures include
                    encryption, firewalls, and secure servers. However, no method of transmission or storage is 100% secure, and we cannot guarantee
                    absolute security.
                </p>

                <h2>5. Data Retention</h2>
                <p>
                    We retain your personal data for as long as necessary to provide the Services or fulfill the purposes outlined in this Privacy Policy.
                    User-generated content may be retained indefinitely for operational, marketing, or legal purposes, even after account termination.
                </p>

                <h2>6. Your Rights</h2>
                <p>Depending on your location, you may have the following rights:</p>
                <ul>
                    <li>
                        <strong>Access and Correction:</strong> Request access to your personal data or correct inaccuracies.
                    </li>
                    <li>
                        <strong>Deletion:</strong> Request the deletion of your personal data, subject to legal or operational obligations.
                    </li>
                    <li>
                        <strong>Data Portability:</strong> Receive a copy of your data in a portable format.
                    </li>
                    <li>
                        <strong>Opt-Out:</strong> Manage cookie preferences or unsubscribe from promotional communications.
                    </li>
                </ul>
                <p>
                    To exercise your rights, please contact us at <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>

                <h2>7. Cookies and Tracking Technologies</h2>
                <p>
                    We use cookies and similar technologies to enhance user experience and analyze usage patterns. You can manage cookie preferences
                    through your browser settings or opt out of tracking in regions where required by law.
                </p>

                <h2>8. Third-Party Services</h2>
                <p>
                    Our Services may integrate with or link to third-party platforms (e.g., analytics tools, payment processors). We are not responsible
                    for the privacy practices of these third parties. Please review their policies to understand how they handle your data.
                </p>

                <h2>9. International Data Transfers</h2>
                <p>
                    If you access the Services outside Romania, your data may be transferred to and processed in Romania or other countries where we or
                    our service providers operate. By using the Services, you consent to such transfers.
                </p>

                <h2>10. Children’s Privacy</h2>
                <p>
                    The Services are not intended for users under 16 years old. If we learn that we have collected data from a user under the required
                    minimum age, we will delete it promptly.
                </p>

                <h2>11. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. If changes are made, we will notify you by posting the updated policy on our
                    Site or App. Your continued use of the Services after the updates constitutes acceptance of the revised policy.
                </p>

                <h2>12. Contact Information</h2>
                <p>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
                <p>Email: <a href="mailto:hello@solt.app">hello@solt.app</a></p>
                <p>Website: <a href="https://www.solt.app">www.solt.app</a></p>
            </div>
        </>
    );
};

export default Privacy;
