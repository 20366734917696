import React, {useEffect, useRef, useState} from 'react';
import {ToastContainer, toast, Id} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getAllPostsWithCreatorDetails,
    getPostsByLink,
    getUsernameByFirebaseUID,
    searchPosts,
    updateReactionCount
} from '../api';
import './Restaurant.css';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import ClipLoader from 'react-spinners/ClipLoader';
import soltLogo from '../assets/logo.png';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import ReactGA from "react-ga4";
import posthog from 'posthog-js';
import {formatDistanceToNow} from 'date-fns';
import BottomModal from "../components/BottomModal";

import redirectIcon from '../assets/redirect-icon.png';
import closeIcon from '../assets/close-icon.png'; // The icon for closing the modal
import infoIcon from '../assets/info-icon.png';
import searchIcon from '../assets/search-icon.png'; // Add search icon path
import enterIcon from '../assets/enter-icon.png'; // Add enter icon path
import exitIcon from '../assets/exit-icon.png';
import sendIcon from '../assets/send-icon.png'; // Add send icon path
import starIcon from '../assets/star-icon.png';

import likedReactionIcon from "../assets/liked-reaction-icon.png";
import normalReactionIcon from "../assets/normal-reaction-icon.png";
import restaurantPlaceIcon from "../assets/restaurant-place-icon.png";
import {extractLocationInfo} from "../helpers/helpers";
import {Link, useParams} from "react-router-dom";

ReactGA.initialize("G-TLM63YPZGE");

declare const plausible: (eventName: string, options?: { props: Record<string, string> }) => void; // Plausible typing

interface RestaurantParams {
    link: string;
}

interface RestaurantPost {
    post: {
        _id: string | number;
        images: string[];
        title: string;
        description: string;
        location?: string;
        updated?: string;
        google_place_id?: string;
        location_english_formatted?: string;
        created?: string;
        reactionCount?: number;
        ratings?: {
            dishRating?: number; // New field for taste rating
            wouldEatAgain?: boolean; // New field for "Would you eat it again?"
        };
    },
    creator: {
        name: string;
        username: string;
        profileImage?: string;
    };
}

posthog.init('phc_Pz5SLU7O6UiCM3I5oabytoGoVTAkiMdROvO34hzDmiI', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'always',
});

const Restaurant: React.FC = () => {
    const {link} = useParams<{ link: string }>();
    // const { link } = useParams<RestaurantParams>(); // Get the link parameter from the URL

    const [posts, setPosts] = useState<RestaurantPost[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedPost, setSelectedPost] = useState<RestaurantPost | null>(null);
    const [isViewing, setIsViewing] = useState<boolean>(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [animationClasses, setAnimationClasses] = useState<{ [key: string]: string }>({}); // Key-value store for postId and animation class

    const pageDescription = `Explore the best dishes at top restaurants, handpicked by food content creators. Discover unique flavors, see where to eat them, and share your own foodie favorites.`;

    useEffect(() => {
        const fetchPostsByLink = async () => {
            console.log(link);
            if (!link) {
                setError("Invalid link.");
                setLoading(false);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const result = await getPostsByLink(link);

                if (result === 'not-found') {
                    setError("No posts found for this link.");
                } else if (result === 'error') {
                    setError("An unexpected error occurred.");
                } else if (result === 'missing-link') {
                    setError("No link found.");
                } else {
                    console.log(result);
                    setPosts(result); // Set the fetched posts
                }
            } catch (error) {
                console.error("Error fetching posts:", error);
                setError("An unexpected error occurred.");
            } finally {
                setLoading(false);
            }
        };

        fetchPostsByLink();
    }, [link]);

    useEffect(() => {
        if (isOverlayOpen) {
            document.documentElement.classList.add('overlay-open');
        } else {
            document.documentElement.classList.remove('overlay-open');
        }

        return () => {
            document.documentElement.classList.remove('overlay-open');
        };
    }, [isOverlayOpen]);

    const handleReactionClick = async (username: string, postId: string | number) => {
        // Create a unique key for localStorage
        const localStorageKey = `${postId}_liked`; // the post id in home is already like this: creatorid_postid

        // Check if the user has liked the post (true means liked, false means not liked)
        const isLiked = localStorage.getItem(localStorageKey) === 'true';
        localStorage.setItem(localStorageKey, (!isLiked).toString()); // Toggle the boolean value

        // Determine if we are incrementing or decrementing
        const increment = !isLiked; // If not liked, we want to increment; if liked, we decrement

        if (!isLiked) {
            // Add animation class for this postId
            setAnimationClasses((prev) => ({
                ...prev,
                [postId]: 'fill-animation',
            }));

            // Remove animation class after animation duration
            setTimeout(() => {
                setAnimationClasses((prev) => ({
                    ...prev,
                    [postId]: '', // Reset the animation class
                }));
            }, 600); // 600ms to match the animation duration
        }

        // Update the post state with the new reaction count and toggle the like status
        setPosts(posts.map(post => {
            if (post.post._id !== postId) return post;

            // If reactionCount is undefined, default it to 0
            const currentCount = post.post.reactionCount || 0;

            // Ensure the reactionCount does not go below 0
            const newReactionCount = isLiked
                ? Math.max(currentCount - 1, 0) // Ensure it doesn't decrement below 0
                : currentCount + 1;

            // Return a new updated post object
            return {
                ...post,
                post: {
                    ...post.post,
                    reactionCount: newReactionCount // Make sure we're updating this deeply
                }
            };
        }));

        // Extract only the postId by splitting the combinedId (creatorid_postid)
        const actualPostId = (postId.toString()).split('_')[1]; // The second part is the actual postId
        // Send the reaction update to the backend
        const result = await updateReactionCount(username!, actualPostId.toString(), increment);

        if (result === true) {
            console.log('updated reaction count');
        } else {
            localStorage.setItem(localStorageKey, (isLiked).toString()); // Toggle the boolean value back in case it fails.
            console.error('Error updating reaction count in the backend');
            // Optionally, revert the optimistic UI update if backend fails
            setPosts(posts.map(post => {
                if (post.post._id !== postId) return post;

                // Revert the reaction count to its previous value
                const currentCount = post.post.reactionCount || 0;
                const revertedReactionCount = isLiked
                    ? currentCount + 1 // Undo decrement
                    : Math.max(currentCount - 1, 0); // Undo increment but ensure no negative values

                // Return a new updated post object
                return {
                    ...post,
                    post: {
                        ...post.post,
                        reactionCount: revertedReactionCount // Make sure we're updating this deeply
                    }
                };
            }));
        }
    };

    const handleViewPost = (homePost: RestaurantPost) => {
        ReactGA.set({page: `/`});
        ReactGA.event({
            category: 'dish_interaction',
            action: 'dish_click',
            label: homePost.post.title,
            value: Number(homePost.post._id),
            nonInteraction: false,
            transport: 'xhr',
        });

        posthog.capture('dish_click', {
            category: 'dish_interaction',
            action: 'click',
            title: homePost.post.title,
            postId: homePost.post._id,
            path: window.location.pathname,
            username: homePost.creator.username,
        });

        // Track with Plausible
        if (typeof plausible !== "undefined") {
            plausible('Dish Click', {
                props: {
                    path: window.location.pathname,
                    dish_id: homePost.post._id.toString(),
                    dish: homePost.post.title,
                    creator: homePost.creator.username,
                }
            });
        }

        if (typeof window.sa_event !== 'undefined') {
            window.sa_event("dish_click", {
                dish_id: homePost.post._id.toString(),
                dish: homePost.post.title,
                creator: homePost.creator.username,
                path: window.location.pathname
            });
        } else {
            console.warn("Simple Analytics not initialized");
        }

        setSelectedPost(homePost);
        setIsViewing(true);
        setIsOverlayOpen(true);
    };

    const closeModal = () => {
        setIsViewing(false);
        setSelectedPost(null);
        setIsOverlayOpen(false);
    };

    const getRelativeTime = (dateString: string | undefined) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return formatDistanceToNow(date, {addSuffix: true});
    };

    const noPostsAvailable = !posts || posts.length === 0;


    const handleGoogleMapsClick = (homePost: RestaurantPost) => {
        if (typeof plausible !== "undefined") {
            plausible('Google Maps Click', {
                props: {
                    path: window.location.pathname,
                    dish_id: homePost.post._id?.toString(),
                    dish: homePost.post.title,
                    creator: homePost.creator.username,
                    restaurant: homePost.post.location ? homePost.post.location : ''
                }
            });
        }

        if (typeof window.sa_event !== 'undefined') {
            window.sa_event("google_maps_click", {
                path: window.location.pathname,
                dish_id: homePost.post._id?.toString(),
                dish: homePost.post.title,
                creator: homePost.creator.username,
                restaurant: homePost.post.location ? homePost.post.location : ''
            });
        }
    };


    const {
        restaurant = '',
        city = '',
        country = ''
    } = posts && posts[0] ? extractLocationInfo(posts[0].post.location || '') : {};

    // const pageTitle = restaurant + " recommended dishes by foodies";
    const pageTitle = restaurant;
    const pageSubtitle = "Dishes recommended by Solt foodies";

    return (
        <>
            <Helmet>
                <title>Solt | The place for foodies</title>
                <meta name="description" content={pageDescription}/>
                <link rel="canonical" href="https://solt.app/"/>
            </Helmet>

            <div className="home-container">
                <div className="home-header">
                    <div className="home-header-top">
                        <Link to={'/'}>
                            <img src={soltLogo} alt="Solt logo" className="solt-logo"/>
                        </Link>
                    </div>
                </div>

                <div className="title-container">
                    <h2 className="restaurant-title">{pageTitle}</h2>
                    {city && <p className="restaurant-subtitle">{city}, {country}</p>}
                    {/*{posts && posts[0] && posts[0].post && <a*/}
                    {/*    href={*/}
                    {/*        posts[0].post.google_place_id*/}
                    {/*            ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(posts[0].post.location || "Place Name")}&query_place_id=${posts[0].post.google_place_id}`*/}
                    {/*            : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(posts[0].post.location || "")}`*/}
                    {/*    }*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noopener noreferrer"*/}
                    {/*    className="google-maps-icon"*/}
                    {/*    onClick={() => handleGoogleMapsClick(posts[0])} // Track Google Maps Click*/}
                    {/*>*/}
                    {/*    <img src={redirectIcon} alt="Google Maps"/>*/}
                    {/*</a>*/}
                    {/*}*/}
                    {restaurant && <p className="restaurant-subtitle">{pageSubtitle}</p>}
                </div>


                <div className={"bar"}/>

                {/* Loading spinner in the center */}
                {loading && <div className="loading-message"><ClipLoader size={50}/></div>}

                {loading && <div className="loading-message"><p>Loading posts...</p></div>}

                {!loading && noPostsAvailable && (
                    <div className="no-posts-message">
                        <p>No posts available.</p>
                    </div>
                )}

                {!loading && !error && !noPostsAvailable ? (
                    <ResponsiveMasonry columnsCountBreakPoints={{1: 1, 350: 2, 900: 3}}>
                        <Masonry gutter="10px">
                            {posts.map((restaurantPost) => {

                                return (
                                    restaurantPost && restaurantPost.post && restaurantPost.creator &&
                                    restaurantPost.post.images && restaurantPost.post.images.length > 0 && (
                                        <div key={restaurantPost.post._id} className="post-card">


                                                <div className="profile-image-container">
                                                    <img src={restaurantPost.post.images[0]}
                                                         loading="lazy"
                                                         alt={restaurantPost.post.title}
                                                         className="post-image"
                                                         onClick={() => handleViewPost(restaurantPost)}/>

                                                    {/*<div className="dish-title-overlay">*/}
                                                    {/*    <h3>{restaurantPost.post.title}</h3>*/}
                                                    {/*</div>*/}


                                                    {/* Single Reaction positioned bottom-right */}
                                                    <div className="single-reaction-overlay">
                                                        <div
                                                            className="reaction-item"
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevents the image click event
                                                                handleReactionClick(restaurantPost.creator.username, restaurantPost.post._id); // Handle reaction click separately
                                                            }}
                                                        >
                                                            <img
                                                                src={localStorage.getItem(`${restaurantPost.post._id}_liked`) === 'true' ? likedReactionIcon : normalReactionIcon}
                                                                alt="Reaction Icon"
                                                                className={`reaction-icon ${animationClasses[restaurantPost.post._id] || ''}`} // Use animation state instead of post object
                                                            />
                                                            <span
                                                                className="reaction-number">{restaurantPost.post.reactionCount || 0}</span> {/* Fallback to 0 if missing */}
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="post-info">
                                                    {/*<h3>{restaurantPost.post.title}</h3>*/}
                                                    <div className="post-info-header">
                                                        <h3
                                                            className="post-title clickable"
                                                            onClick={() => handleViewPost(restaurantPost)}
                                                        >
                                                            {restaurantPost.post.title}
                                                        </h3>
                                                        {restaurantPost.post.ratings?.dishRating && (
                                                            <div className="post-rating">
                                                                <img src={starIcon} alt="Star Icon"
                                                                     className="review-star-icon"/> {/* Replace with actual star icon path */}
                                                                <span>{Number(restaurantPost.post.ratings.dishRating || 0).toFixed(2)}</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/*{(restaurantPost.post.location_english_formatted || restaurantPost.post.location) && (*/}
                                                    {/*    <div className="card-location-container">*/}
                                                    {/*        <img src={restaurantPlaceIcon} alt="Location Icon"*/}
                                                    {/*             className="card-location-icon"/>*/}
                                                    {/*        <p className="card-location-text">*/}
                                                    {/*            {restaurantPost.post.location_english_formatted*/}
                                                    {/*                ? restaurantPost.post.location_english_formatted*/}
                                                    {/*                : `${restaurant}, ${city}, ${country}`}*/}
                                                    {/*        </p>*/}
                                                    {/*    </div>*/}
                                                    {/*)}*/}

                                                    {restaurantPost.post.created &&
                                                        <div className="post-card-footer">
                                                            <div className="creator-info">
                                                                <img
                                                                    src={restaurantPost.creator.profileImage || './profile_image_placeholder.png'}
                                                                    alt={restaurantPost.creator.username}/>
                                                                <div className="creator-info-text">
                                                                    <span
                                                                        style={{fontWeight: 400}}>Recommended by&nbsp;</span>
                                                                    <span><Link
                                                                        to={`/${restaurantPost.creator.username}`}>{restaurantPost.creator.username}</Link></span>
                                                                    <span>&nbsp;</span>
                                                                    <span
                                                                        style={{fontWeight: 400}}>and others</span>
                                                                    {/*<span>others</span>*/}
                                                                </div>
                                                                {/*<span*/}
                                                                {/*    style={{fontWeight: 500}}>{getRelativeTime(restaurantPost.post.created)}</span>*/}

                                                            </div>
                                                            {/*<span>{getRelativeTime(restaurantPost.post.created)}</span>*/}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            )}
                        </Masonry>
                    </ResponsiveMasonry>
                ) : (
                    !loading && !error && <div className="no-posts-message"><p>No posts available.</p></div>
                )}


                <BottomModal isOpen={isViewing} onClose={closeModal}>
                    {selectedPost && (
                        <div className="home-overlay-content">
                            <div className="modal-bar"></div>
                            {/* Add this line */}

                            <div className="home-fixed-image-box">

                                <img
                                    src={selectedPost.post.images[0].includes('_COMPRESSED.webp')
                                        ? selectedPost.post.images[0].replace('_COMPRESSED.webp', '_ORIGINAL.jpeg')
                                        : selectedPost.post.images[0] // Fallback to original URL if no match
                                    }
                                     alt={selectedPost.post.title}
                                     className="home-fixed-image"/>
                            </div>


                            {/* Container for title and rating */}
                            <div className="overlay-title-rating-container">
                                <h3 className="overlay-post-title">{selectedPost.post.title}</h3>
                                {selectedPost.post.ratings?.dishRating && (
                                    <div className="overlay-post-rating">
                                        <img src={starIcon} alt="Star Icon" className="overlay-review-star-icon"/>
                                        <span>{Number(selectedPost.post.ratings.dishRating).toFixed(2)}</span>
                                    </div>
                                )}
                            </div>

                            {selectedPost.post.created && (
                                <div className="creator-info-modal">
                                    <Link to={`/${selectedPost.creator.username}`}>
                                        <img
                                            src={selectedPost.creator.profileImage || './profile_image_placeholder.png'}
                                            alt={selectedPost.creator.username}
                                            className="creator-profile-image-modal"
                                        />
                                    </Link>
                                    <span>
                                        Added by{' '}
                                        <Link to={`/${selectedPost.creator.username}`}
                                              className="no-style-link" style={{fontWeight: 'bold'}}>
                                            {selectedPost.creator.username}
                                         </Link>{' '}
                                        {getRelativeTime(selectedPost.post.created)}
                                    </span>
                                </div>
                            )}

                            {/*<div className="bar"></div>*/}

                            {/*<div className="info-card">*/}
                            {/*    <h4>Where to eat:</h4>*/}
                            {/*    <div className="location-container">*/}
                            {/*        <p className="location-text">{selectedPost.post.location_english_formatted || selectedPost.post.location}</p>*/}
                            {/*        <a*/}
                            {/*            href={*/}
                            {/*                selectedPost.post.google_place_id*/}
                            {/*                    ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.post.location || "Place Name")}&query_place_id=${selectedPost.post.google_place_id}`*/}
                            {/*                    : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.post.location || "")}`*/}
                            {/*            }*/}
                            {/*            target="_blank"*/}
                            {/*            rel="noopener noreferrer"*/}
                            {/*            className="google-maps-icon"*/}
                            {/*            onClick={() => handleGoogleMapsClick(selectedPost)} // Track Google Maps Click*/}
                            {/*        >*/}
                            {/*            <img src={redirectIcon} alt="Google Maps"/>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            {selectedPost.post.description && <div className="bar"></div>}

                            {selectedPost.post.description && (
                                <div className="info-card">
                                    <h4>Description:</h4>
                                    <p className="description-text">{selectedPost.post.description}</p>
                                </div>
                            )}

                            {/*<div className="buttons-container">*/}
                            {/*    <button className="form-submit-btn">*/}
                            {/*        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.post.location || "")}`}*/}
                            {/*           target="_blank" rel="noopener noreferrer"*/}
                            {/*           style={{textDecoration: 'none', color: 'white'}}>*/}
                            {/*            Open in Google Maps*/}
                            {/*        </a>*/}
                            {/*    </button>*/}
                            {/*    /!*<button onClick={closeModal} className="form-cancel-btn">Close</button>*!/*/}
                            {/*</div>*/}
                        </div>
                    )}
                </BottomModal>

                <ToastContainer
                    autoClose={1000}
                    theme="colored"
                    draggable
                    hideProgressBar
                    closeButton={false}
                    newestOnTop
                />
            </div>

            <div className="home-footer">
                <div className={"bar"}/>
                <button className="footer-button"
                        onClick={() => window.open('https://solt.app/creators', '_blank')}>
                    Share what you eat on Solt
                </button>
            </div>
        </>
    );
};

export default Restaurant;
