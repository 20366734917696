import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import soltLogo from '../assets/logo.png';
import soltApp from '../assets/solt-app.png';
import analyticsImage from '../assets/analytics-image.png';
import linkinbioImage from '../assets/link-in-bio-image.png';
import homepageImage from '../assets/homepage-image.png';
import soltFoodiesImage from '../assets/solt-foodies-image.png';
import soltFoodiesImageMobile from '../assets/solt-foodies-image-mobile.png';

import './Creators.css';
import Footer from "../components/Footer";

const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
};


function Creators() {
    // const [loading, setLoading] = useState(true);
    //
    // useEffect(() => {
    //     // Function to check if all images are loaded
    //     const handleImageLoad = () => {
    //         const images = document.querySelectorAll('img');
    //         const allImagesLoaded = Array.from(images).every((img) => img.complete);
    //         if (allImagesLoaded) {
    //             setLoading(false);
    //         }
    //     };
    //
    //     // Attach load event listeners to images
    //     const images = document.querySelectorAll('img');
    //     images.forEach((img) => {
    //         if (!img.complete) {
    //             img.addEventListener('load', handleImageLoad);
    //             img.addEventListener('error', handleImageLoad); // Handle broken images too
    //         }
    //     });
    //
    //     // If all images are already loaded (cached)
    //     handleImageLoad();
    //
    //     // Cleanup event listeners on unmount
    //     return () => {
    //         images.forEach((img) => {
    //             img.removeEventListener('load', handleImageLoad);
    //             img.removeEventListener('error', handleImageLoad);
    //         });
    //     };
    // }, []);
    //
    // if (loading) {
    //     return (
    //         <div className="loading-screen">
    //             <div className="spinner">Loading...</div>
    //         </div>
    //     );
    // }

    return (
        <>
            {/*Intro section*/}

            <title>Solt | Foodie Creators</title>
            <meta name="description"
                  content="Discover how food content creators grow on Solt. Showcase your favorite dishes, connect with food lovers, and monetize your passion with powerful analytics and engagement tools. Join Solt today and grow your foodie following!"/>
            <link rel="canonical" href="https://solt.app/creators"/>
            <meta property="og:url" content="https://solt.app/creators"/>

            <header className="App-header">
                <div className="logo-container">
                    <Link to="/">
                        <img className="logo" src={soltLogo} alt="Solt App Logo"/>
                    </Link>
                    <a href="/creator/sign-up" className="login-button" target={"_blank"}>Login as Creator</a>

                </div>
            </header>


            <div className="header-section">
                <h1>The place for foodies</h1>
                <p>Create food content, grow a following, monetize your community.</p>


                {/* Desktop image */}
                <picture>
                    <source srcSet={soltFoodiesImage} media="(min-width: 768px)"/>
                    {/* Mobile image */}
                    <img src={soltFoodiesImageMobile} alt="Foodies creating content"/>
                </picture>

                <section>
                    {/*<h1 className="benefits-section-h1">Start creating content now</h1>*/}
                    <div className="buttons"
                         style={{marginRight: 'auto', marginLeft: 'auto', width: 'fit-content'}}>
                        <Link className="button" to="/creator/sign-up" target="_blank" rel="noopener noreferrer">
                            Become a Solt Foodie Creator
                        </Link>
                        {/*<a className="button" href="mailto:hello@solt.app" target="_blank"*/}
                        {/*   rel="noopener noreferrer">Get started</a>*/}
                    </div>
                </section>
            </div>

            <section>
                <h1 className="benefits-section-h1">What do you get as a Solt Foodie Creator?</h1>

                <section className="blog">
                    <div className="content-section" style={{textAlign: 'left'}}>
                        <h1>Your own Solt link-in-bio</h1>
                        <p>Get your own link-in-bio to share your Solt feed with your social media
                            community. <br/><br/> Your link will be available
                            at: solt.app/username</p>
                        {/*<h4>Share the dishes you eat and recommend</h4>*/}

                        {/*<div className="buttons">*/}
                        {/*    <Link className="button" to="/creator/sign-up">Get started</Link>*/}
                        {/*    /!*<a className="button" href="mailto:hello@solt.app" target="_blank"*!/*/}
                        {/*    /!*   rel="noopener noreferrer">Get started</a>*!/*/}
                        {/*</div>*/}
                    </div>
                    <div className="phone-image" style={{textAlignLast: 'right'}}>
                        <img src={linkinbioImage} alt="Solt Webapp"/>
                    </div>
                </section>

                <section className="blog">
                    <div className="phone-image" style={{textAlignLast: 'left'}}>
                        <img src={homepageImage} alt="Solt Webapp"/>
                    </div>

                    <div className="content-section" style={{textAlign: 'right'}}>
                        <h1>Your content will appear on Solt homepage</h1>
                        <p>The content you create will be available to all people who use Solt as their food discovery
                            tool.
                            <br/><br/>A bigger audience, more chances to grow.</p>
                    </div>
                </section>


                <section className="blog">
                    <div className="content-section" style={{textAlign: 'left'}}>
                        <h1>Stats</h1>
                        <p>Have your own analytics tool. See things like profile views, dish views, dish clicks, etc.

                            <br/><br/>Use this info to better your content and grow your community.</p>
                        {/*<h4>Share the dishes you eat and recommend</h4>*/}

                        <div className="buttons">
                            <Link className="button" to="">Coming soon!</Link>
                            {/*<a className="button" href="mailto:hello@solt.app" target="_blank"*/}
                            {/*   rel="noopener noreferrer">Get started</a>*/}
                        </div>
                    </div>
                    <div className="phone-image" style={{textAlignLast: 'right'}}>
                        <img style={{maxWidth: '80%'}} src={analyticsImage} alt="Solt Webapp"/>
                    </div>
                </section>


                <section className="blog">
                    <div className="phone-image" style={{textAlignLast: 'left'}}>
                        <img src={soltApp} alt="Solt Webapp"/>
                    </div>

                    <div className="content-section" style={{textAlign: 'right'}}>
                        <h1>Earn money</h1>
                        <p>Make money with Solt. <br/>Subscription, tip jars, sponsorships, brand deals, etc.
                            <br/><br/>
                            Pick the tools that best suit you and your content and monetize your work.</p>

                        <div className="buttons" style={{marginRight: 0, marginLeft: 'auto'}}>
                            <Link className="button" to="">Coming soon!</Link>
                            {/*<a className="button" href="mailto:hello@solt.app" target="_blank"*/}
                            {/*   rel="noopener noreferrer">Get started</a>*/}
                        </div>
                    </div>


                </section>

                {/*/!* Partners Section *!/*/}
                {/*<section className="blog">*/}
                {/*    <div className="blog-image">*/}
                {/*        <img src={chefImage} alt="Solt Partners Image"/>*/}
                {/*    </div>*/}

                {/*    <div className="blog-content">*/}
                {/*        <h2>Do you like to create food content?</h2>*/}
                {/*        <p>Create a Solt profile and start posting dish recommendations from restaurants. </p>*/}
                {/*        <p>Your profile will be available at solt.app/@username</p>*/}
                {/*        <p>Build your food community, monetize your cravings.</p>*/}

                {/*        <Link className="button" to="/creator/sign-up">Create your profile</Link>*/}
                {/*        /!*<a className="button" href="mailto:hello@solt.app" target="_blank"*!/*/}
                {/*        /!*   rel="noopener noreferrer">hello@solt.app</a>*!/*/}
                {/*        /!*<a className="button" href="tel:0040741333306" target="_blank" rel="noopener noreferrer">+40741333306</a>*!/*/}
                {/*    </div>*/}


                {/*</section>*/}

            </section>
            {/*<div className={"footer-bar"}/>*/}

            <section style={{paddingTop: '5%'}}>
                <h1 className="benefits-section-h1">Become a Solt Foodie Creator</h1>
                <div className="buttons" style={{marginRight: 'auto', marginLeft: 'auto', width: 'fit-content'}}>
                    <Link className="button" to="/creator/sign-up" target="_blank" rel="noopener noreferrer">
                        Get started
                    </Link>
                </div>


            </section>

            <section style={{paddingTop: '5%'}}>
                <h1 className="benefits-section-h1">More questions about this?</h1>
                <p className="benefits-section-p">Send an email to <a href="mailto:hello@solt.app">hello@solt.app</a> or
                    send a DM on instagram: <a href="https://www.instagram.com/solt.app" target="_blank">solt.app</a>.
                    We will reply fast.</p>
            </section>

            <Footer/>
            {/*<footer className="footer">*/}
            {/*    <div className="footer-left">*/}
            {/*        <img src={soltLogo} alt="Solt App Logo" className="footer-logo"/>*/}
            {/*        <p>The place for foodies and food reviewers</p>*/}
            {/*    </div>*/}

            {/*    <div className="footer-right">*/}
            {/*        <div className="social-links">*/}
            {/*            /!*<a href="https://www.tiktok.com/@solt.app" target="_blank" rel="noopener noreferrer"><img src={tiktokIcon} alt="TikTok" /></a>*!/*/}
            {/*            /!*<a href="https://www.instagram.com/solt.app" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram"/></a>*!/*/}
            {/*        </div>*/}
            {/*        <p>Contact: <a href="mailto:hello@solt.app">hello@solt.app</a></p>*/}
            {/*        <Link to="/terms">Terms and Conditions</Link>*/}
            {/*        <br/>*/}
            {/*        <Link to="/privacy">Privacy Policy</Link>*/}
            {/*    </div>*/}
            {/*</footer>*/}

        </>
    );
}

export default Creators;
